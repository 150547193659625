// Note: Enum key name must be exact toggle name!
export enum ToggleLabelMap {
    applyTaxesAndFees = 'Apply Taxes And Fees?',
    areDealerSettingOverridesEnabled = 'Enable Dealer Setting Overrides?',
    calculateOnMSRP = 'Cash Payments For New Vehicles Based On',
    calculateOnMSRPAlternate = 'Lease Payments Based On',
    canNegotiateNew = 'Negotiation Type: New',
    canNegotiateUsed = 'Negotiation Type: Used',
    ecommerce = 'Ecommerce',
    excludeSpecialAPRLeaseOffersNewCar = 'Exclude Special APRs for Lease Offers - New Car?',
    excludeSpecialAPRLoanOffersUsedCar = 'Exclude Special APRs for Loan Offers - Used Car?',
    excludeSpecialAPRLoanOffersNewCar = 'Exclude Special APRs for Loan Offers - New Car?',
    enableMaxCashIncentives = 'Enable Max Cash Incentives?',
    enablePrePurchaseAgreement = 'Purchase Agreement Enabled?',
    enableUnifyDealSummary = 'Display UniFI Deal Summary',
    enableItemizedManufacturerIncentives = 'Enable Itemized OEM Incentives',
    excludeStdCashIncsCash = 'Exclude Cash Offer from Cash Price?',
    excludeStdCashIncsLease = 'Exclude Cash Offer from Lease Price?',
    excludeStdCashIncsLoan = 'Exclude Cash Offer from Loan Price?',
    hasCreditDecision = 'Credit Decision',
    hasLeasing = 'Is Leasing Enabled?',
    hasUsedCashOffer = 'Enable Cash Deals for Used Vehicles',
    hasNewCashOffer = 'Enable Cash Deals for New Vehicles',
    hasWidgetNew = 'New Car Widget Active?',
    hasWidgetNewListingSite = 'New Car Widget Active on Listing Site?',
    hasWidgetUsed = 'Used Car Widget Active?',
    hasWidgetUsedListingSite = 'Used Car Widget Active on Listing Site?',
    hasFi = 'Is Vehicle Protection Active?',
    hasAccessories = 'Is Accessories Catalog Active?',
    hasTestDriveAtHome = 'Enable Test Drive at Home',
    hasShowroom = 'Enable Deal Lookup',
    howItWorksVideoUrl = 'How It works Video',
    howItWorksPosterUrl = 'How It Works Poster',
    isAccelerateCreditAppEnabled = 'Enable Credit App Redesign',
    isAccelerateCreditDecisionEnabled = 'Enable Credit Decision Redesign',
    isConsumerFacingCreditDecisionEnabled = 'Enable Consumer-Facing Credit Decision',
    consumerFacingCreditDecisionType = 'Consumer-Facing Decision Type',
    isActivEngageEnabled = 'Enable ActivEngage Chat',
    accelerateMyDeal = 'Accelerate My deal',
    amd = 'AMD',
    standaloneCreditApp = 'Standalone Credit App',
    isCCPAEnabled = 'CCPA Enabled?',
    isChatEnabled = 'Display Chat?',
    isChatUpgradeEnabled = 'Live Person Chat Upgrade',
    isContinueToDeskingDisabled = 'Hide Continue to Desking link',
    isDealertrackCreditProvider = 'Credit Provider',
    isDefaultHeaderTextEnabled = 'Is Default Header Text Enabled?',
    isDIOEnabled = 'Enable Dealer Installed Options',
    isDocUploadEnabled = 'Enable Document Upload',
    isDownPaymentFixedAmount = 'Cash Down Method',
    isDueAtSigninAmountDeduct = 'How to apply down payment',
    isEmployerAddressRequired = 'Employer Address Required?',
    isMenuFitToHeightEnabled = 'Image Display',
    isMenuPricingDisabled = 'Turn off Menu Pricing?',
    isAccessoriesPricingDisabled = 'Turn off Accessories Pricing?',
    isMonthlyPaymentDueAtSigning = 'Default First Payment Due at Signing? ',
    isPaymentDriverPreferred = 'Finance/Lease Deals Payment Source',
    isPaymentDriverPreferredForCashDeals = 'Cash Deals Payment Source',
    isPhoneEnabled = 'Phone Tracking Feature',
    isPhoneRequired = 'Phone Number Required',
    isPhoneRequiredForConsentDisplay = 'Require Phone Number for Consent Display?',
    isRedesignEnabled = 'Enable Redesign',
    isSoftPullEnabled = 'Enable Soft Pull',
    isSmsEnabled = 'SMS Feature',
    isSubaruGtpEnabled = 'Enable Subaru Guaranteed Trade?',
    isTCPACheckboxDisabled = 'TCPA Hide Checkbox?',
    isTCPAEnabled = 'Marketing Consent',
    isTestDealer = 'Is Test Dealer?',
    isTradeInBranded = 'Vehicle Trade-In Button',
    isTestDriveAvailableSunday = 'Is Test Drive Available on Sundays?',
    isVDPLeadFormEnabled = 'Is Lead Form Enabled on Vehicle Detail Page?',
    isWidgetHeightDynamic = 'Is Widget Height Dynamic?',
    leadGatePaymentOnDS = 'Show Lead Form Before Payment on DS?',
    leadRoutingOverrideEnabled = 'Use Dealertrack Lead Override?',
    multipleSecurityDepositLease = 'Include programs with multiple security deposits?',
    overrideDRSActive = 'Override eBiz Account ID?',
    reviewDealUrlEnabled = 'Review Deal URL',
    shouldSendCreditDecisionEmail = 'Send Credit Decision Email',
    enableLeadFormBeforeCreditApp = 'Enable Lead Form Before Credit App',
    customizedPrivacyNotice = 'Credit App Privacy Notice URL',
    showReservationDetail = 'Enable Reservations?',
    enableTradeDecisionPage = 'Show Trade Decision Page?',
    enableManualTradeDecision = 'Enable Manual Entry Trade?',
    tradeInValuationLeadForm = 'Show Lead Form Before Valuation?',
    useZipCodeService = 'Is Zipcode Services Enabled?',
    isVehicleDeliveryEnabled = 'Enable Vehicle Delivery',
    enableDealerShowroomExperience = 'Enable Showroom Experience',
    enableCheckoutAnywhereInMV = 'Enable Checkout In Manager View',
    enableCheckoutAnywhereInSV = 'Enable Checkout In Sales View',
    enableOccIntegration = 'Enable OCC Integration',
    showCreditApplicationDisclaimer = 'Show Credit App Disclaimer on Lead Forms?',
    enableSwitchVehicle = 'Inventory in CVI',
    enableVauto = 'Enable VAuto Trade Appraisal',
    tradeInHideExcellent = 'Hide "Excellent" as a trade-in value',
    enableDMSIntegration = 'Enable DMS Integration',
    showDealPulseInManagerView = 'Show Deal Pulse in Manager View',
    isTargetPlatformR1J = 'Enable Target Platform R1J',
    salesView = 'Sales View',
    showAppraisalValueScreenSalesView = 'Show Appraisal Value Screen?',
    showAppraisalSalesViewTooltip = 'When this setting is "Yes", the "Did you already receive an estimate?"' +
        ' screen will show in the Dealer Appraisal trade flow',
    showFlexiblePaymentOptions = 'Show Flexible Payment Options',
    showSignatureLine = 'Show Signature Line',
    showPrintItemizedDealBreakdown = 'Show Print Itemized Deal Breakdown',
    enableGhostVin = 'Showroom (3rd party CRM)',
    enableDarwin = 'Enable Darwin'
}
